/* General layout */
.dashboard {
    padding: 20px;
    background: #f5f5f5; /* Light background for contrast */
}

/* Controls section */
.controls {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Date Select */
.date-select {
    min-width: 200px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 15px;
    flex: 1;
    max-width: 250px; /* Limit maximum width for better fit */
}

/* Calendar Icon */
.calendar-icon {
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
    color: #007bff; /* Blue color for better visual appeal */
    transition: color 0.3s ease;
}

.calendar-icon:hover {
    color: #0056b3; /* Darker blue on hover */
}

/* Refresh Button */
.refresh-button {
    background: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px; /* Adjusted icon size */
    max-width: 150px; /* Limit maximum width */
}

/* Refresh Button Icon */
.refresh-button .MuiSvgIcon-root {
    font-size: 24px; /* Adjust icon size */
}

/* Refresh Button Hover */
.refresh-button:hover {
    background: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Clock Styling */
.current-time {
    font-size: 24px; /* Larger font size for visibility */
    color: #333; /* Darker color for contrast */
    background: #ffffff; /* White background */
    border: 1px solid #e0e0e0; /* Subtle border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 15px; /* Padding for spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500; /* Slightly bold text */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    flex: 1; /* Allows the time card to adjust */
    max-width: 100%; /* Prevent overflow */
    box-sizing: border-box; /* Include padding and border in width */
}

/* Clock Hover Effect */
.current-time:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
    transform: scale(1.02); /* Slightly enlarge on hover */
}

/* Metrics Grid */
.metrics-grid {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping if needed */
    justify-content: space-between; /* Space out the cards evenly */
    margin-bottom: 30px; /* Space between metrics and charts */
    gap: 20px; /* Space between cards */
}

/* Card Styling */
.metrics-grid .card {
    padding: 20px; /* Reduced padding for better fit */
    background: linear-gradient(145deg, #ffffff, #f4f4f4); /* Gradient for a modern look */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow */
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 150px;
    flex: 1; /* Ensures cards are responsive */
    min-width: 220px; /* Ensures cards don't become too small */
}

/* Specific styling for each card to ensure they fit nicely */
.card:nth-child(1) {
    background-color: #d1e7dd; /* Light green for variety */
}

.card:nth-child(2) {
    background-color: #cfe2ff; /* Light blue for variety */
}

.card:nth-child(3) {
    background-color: #fff3cd; /* Light yellow for variety */
}

.card:nth-child(4) {
    background-color: #f8d7da; /* Light red for variety */
}

.card-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #444;
}

.card-value {
    color: #111;
    font-weight: bold;
    font-size: 80px; /* Adjusted size for readability */
}

.card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Charts section */
.charts-section {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping if needed */
    gap: 20px;
}

.small-chart {
    padding: 20px;
    background: linear-gradient(145deg, #ffffff, #e0e0e0); /* Gradient for a modern look */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow */
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-width: 220px; /* Ensure cards don’t get too small */
}

.small-chart:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Calendar Modal */
.calendar-modal {
    position: fixed; /* Fixed for better modal behavior */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 12px;
    z-index: 1000; /* Ensure it's on top */
}

.calendar {
    width: 100%; /* Full width for responsiveness */
}

/* Responsive Design */
@media (max-width: 768px) {
    .controls {
        flex-direction: column;
        align-items: stretch;
        gap: 10px; /* Space between elements */
    }

    .current-time {
        width: 100%; /* Full width on mobile */
        margin-bottom: 10px; /* Space between time and controls */
        font-size: 20px; /* Adjust font size for mobile */
    }

    .date-select, .refresh-button {
        margin-bottom: 10px; /* Space between dropdown and refresh button */
        width: auto; /* Allow width to adjust based on content */
        max-width: 100%; /* Prevent overflow */
    }

    .date-select {
        flex: 1; /* Allow it to take available space */
    }

    .refresh-button {
        flex: 1; /* Allow it to take available space */
        margin-top: 0; /* Remove margin-top on mobile */
    }
    
    .metrics-grid {
        flex-direction: column; /* Stack cards vertically on smaller screens */
        align-items: stretch; /* Ensure cards take full width */
    }

    .metrics-grid .card {
        min-width: auto; /* Allow cards to adjust width */
    }

    .charts-section {
        flex-direction: column; /* Stack charts vertically on smaller screens */
    }
}
