/* Header Styling */
.app-bar {
    background-color: #ffffff; /* White background color */
    color: #333333; /* Dark text color for contrast */
    border-bottom: 1px solid #e0e0e0; /* Light border at the bottom */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .menu-button {
    color: #333333; /* Dark color for menu button icon */
  }
  
  .title {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  
  .search-bar {
    flex-grow: 2;
    margin-left: 16px;
  }
  
  .icon-button {
    color: #333333;
    margin-left: 8px;
  }
  
  .username {
    color: #333333;
    margin-left: 16px;
  }
  
  /* Drawer Styling */
  .drawer {
    width: 250px;
    flex-shrink: 0;
    background-color: #ffffff; /* White background color for Drawer */
    border-right: 1px solid #e0e0e0; /* Light border on the right side */
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a 3D effect */
    overflow: auto; /* Ensure content does not overflow */
    transition: transform 0.3s ease; /* Smooth transition for drawer movement */
  }
  
  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0; /* Border at the bottom of the header section */
  }
  
  .drawer-logo {
    display: flex;
    justify-content: center;
    padding: 16px;
  }
  
  .drawer-logo img {
    max-width: 100%;
    height: auto;
  }
  
  .close-button {
    color: #333333; /* Dark color for close icon */
  }
  
  /* Navigation Links */
  .nav-link {
    text-decoration: none;
  }
  
  .list-item {
    color: #333333; /* Dark text color for items */
    padding: 12px 16px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
  
  .list-item:hover {
    background-color: #f5f5f5; /* Light gray background on hover */
    transform: scale(1.02); /* Slightly scale up on hover */
  }
  
  .icon {
    color: #333333; /* Dark icon color */
  }
  
  /* Main Content Styling */
  .main-content {
    flex-grow: 1;
    padding: 16px;
    background-color: #f5f5f5; /* Light gray background color for main content */
    transition: margin-left 0.3s ease; /* Smooth transition for drawer opening/closing */
  }
  
  .shifted {
    margin-left: 250px; /* Offset content for the drawer */
  }
  
  .unshifted {
    margin-left: 0; /* Reset margin when drawer is closed */
  }
  
  /* Modal Styling */
  .MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .MuiBox-root {
    background-color: #ffffff; /* Background color for modal */
    border: 1px solid #e0e0e0; /* Light border for modal */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for modal */
  }
  
  .MuiButton-containedPrimary {
    background-color: #007bff; /* Primary button color */
    color: #ffffff;
  }
  
  .MuiButton-outlinedSecondary {
    color: #007bff; /* Secondary button color */
    border-color: #007bff;
  }
  
  .MuiButton-outlinedSecondary:hover {
    background-color: #f0f0f0; /* Hover color for secondary button */
  }
  